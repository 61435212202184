/* font for headings */
/* @font-face {
  font-family: GT Walsheim;
  src: local('GT Walshemin'), local('GT-Walshemin'),
    url('assets/fonts/GT-Walsheim-Regular.otf') format('opentype');
} */

/* font for details */
/* @font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue'), local('Helvetica-Neue'),
    url('assets/fonts/helveticaneue.woff2') format('woff2');
} */

/* font for headings */
@font-face {
  font-family: 'Open Sans';
  src: local('OpenSans'), local('Open-Sans'),
    url('assets/fonts/OpenSans-Regular.ttf') format('opentype');
}

/* font for details */
@font-face {
  font-family: 'Ubuntu';
  src: local('Ubuntu'), local('ubuntu'), url('assets/fonts/Ubuntu-Regular.ttf') format('opentype');
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Ubuntu, sans-serif !important;
}

* {
  font-family: Open Sans, serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: Open Sans, serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cutomTableHeader {
  height: 40px !important;
  padding: 8px !important;
}

.dataSetsTableHover > tbody > tr {
  height: auto !important;
  vertical-align: middle !important;
}

.dataSetsTableHover > tbody > tr:hover > td {
  border-top: 1px solid #7d95d0;
  height: 40px !important;
  box-sizing: border-box;
  border-bottom: 1px solid #7d95d0 !important;
}

.dataSetsTableHover > tbody > tr:hover > td:first-child {
  box-sizing: border-box;
  border-left: 1px solid #7d95d0;
}

.dataSetsTableHover > tbody > tr:hover > td:last-child {
  box-sizing: border-box;
  border-right: 1px solid #7d95d0;
}

button:focus {
  outline: none !important;
}

.dataSetsTableHover > tbody > tr:hover .info-icon {
  display: block !important;
}

.dataSetsTableHover > tbody > tr:focus-within .info-icon {
  display: block !important;
}
